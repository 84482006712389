import React from 'react';
import PageBlocksRenderer from '../PageBlocksRenderer';

const SharedBlock = ({ data: { block }, next, prev, metaData }) => (
  <PageBlocksRenderer
    next={next}
    prev={prev}
    metaData={metaData}
    container={block}
  />
);

export default SharedBlock;
