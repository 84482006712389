import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import Tablet from '~icons/tablet';
import MagnifyingGlass from '~icons/magnifying-glass';
import Journey from '~icons/journey';

const EntryChoices = ({ data }) => {
  const [level, setLevel] = useLocalStorage('pathway-stage', null);
  let pathwayStage = '';
  useEffect(() => {
    if (level != null) {
      pathwayStage =
        '/children/supporting-you-and-your-family/looking-after-yourself';
    }
  }, []);
  return (
    <div className="w-full flex content-center items-center bg-light-grey px-5 py-12 md:py-24">
      <div className="flex flex-1 items-center text-center flex-col">
        <div className="flex flex-col items-center text-center w-full max-w-screen-xl">
          <h2>{data.title}</h2>
          <p className="text-2xl">{data.subtitle}</p>
        </div>
        <div className="flex flex-col justify-between md:flex-row w-full max-w-screen-xl">
          <div className="flex flex-col items-center justify-evenly w-full py-6 md:py-12 px-6">
            <div className="p-10 mb-6 bg-white rounded-full flex flex-col justify-center items-center">
              <Tablet />
            </div>
            <h3 className="text-dark-blue">
              Answer some <br /> quick questions
            </h3>
          </div>

          <div className="flex flex-col items-center justify-evenly w-full py-6 md:py-12 px-6">
            <div className="p-10 mb-6 bg-white rounded-full flex flex-col justify-center items-center">
              <MagnifyingGlass />
            </div>
            <h3 className="text-dark-blue">
              We'll build <br /> you a guide
            </h3>
          </div>

          <div className="flex flex-col items-center justify-evenly w-full py-6 md:py-12 px-6">
            <div className="p-10 mb-6 bg-white rounded-full flex flex-col justify-center items-center">
              <Journey />
            </div>
            <h3 className="text-dark-blue">
              Follow your <br /> pathway
            </h3>
          </div>
        </div>
        <Link
          className="mt-5 inline-block text-xl no-underline font-medium transition-all bg-dark-blue border-2 border-dark-blue px-6 py-3 text-white rounded-xl hover:text-dark-blue hover:bg-white"
          to={`/pathway${pathwayStage}`}
        >
          Start a pathway
        </Link>
      </div>
    </div>
  );
};

export default EntryChoices;
