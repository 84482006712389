export default function MagnifyingGlass() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_317_36)">
        <path
          d="M62.4699 54.6699L47.4999 38.24C49.7499 34.45 51.0499 30.0299 51.0499 25.2999C51.0399 11.2999 39.6899 -0.0500488 25.6799 -0.0500488C11.6699 -0.0500488 0.319946 11.2999 0.319946 25.2999C0.319946 39.2999 11.6699 50.66 25.6799 50.66C30.6999 50.66 35.3799 49.19 39.3199 46.68L53.6699 62.4C55.4399 64.42 58.5399 64.62 60.5599 62.84L62.0299 61.5499C64.0499 59.7799 64.2499 56.68 62.4699 54.66V54.6699ZM6.96995 24.87C6.96995 14.54 15.3499 6.15995 25.6799 6.15995C36.0099 6.15995 44.3899 14.54 44.3899 24.87C44.3899 35.2 36.0099 43.58 25.6799 43.58C15.3499 43.58 6.96995 35.2 6.96995 24.87Z"
          fill="#1A3092"
        />
        <path
          d="M25.69 28.08C20.28 28.08 15.75 31.95 14.76 37.07C17.65 39.9 21.6 41.63 25.97 41.63C30.08 41.63 33.84 40.09 36.68 37.52C35.88 32.17 31.25 28.08 25.7 28.08H25.69ZM25.69 11.98C21.77 11.98 18.58 15.17 18.58 19.09C18.58 23.01 21.77 26.19 25.69 26.19C29.61 26.19 32.79 23.01 32.79 19.09C32.79 15.17 29.61 11.98 25.69 11.98V11.98Z"
          fill="#1281DD"
        />
      </g>
      <defs>
        <clipPath id="clip0_317_36">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
