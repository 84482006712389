import React from 'react';
import BaseIcon from '../Elements/Icon';

const Icon = ({ data: { name, color }, ...props }) => {
  return (
    <BaseIcon
      name={name}
      fill={color && color.hex}
      originalColorSchema={!color}
      {...props}
    />
  );
};

export default Icon;
