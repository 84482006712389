export default function Tablet() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.15 15.13C47.78 15.13 48.44 15.15 49.05 15.25V4.92996C49.05 3.60996 48.84 2.33996 47.83 1.46996C47.17 0.879956 46.3 0.549956 45.53 0.0799561H5.85996C5.85996 0.0799561 5.78996 0.169956 5.71996 0.199956C3.33996 0.999956 2.32996 2.35996 2.32996 4.84996C2.32996 22.95 2.32996 41.06 2.34996 59.14C2.34996 59.63 2.34996 60.15 2.48996 60.62C3.04996 62.74 4.64996 63.91 6.88996 63.91H44.68C45.69 63.91 46.58 63.6 47.38 62.97C48.65 61.98 49.05 60.64 49.05 59.09V50.7C48.44 50.79 47.78 50.82 47.15 50.82C46.28 50.82 45.43 50.75 44.61 50.63V54.84H6.77996V5.36996H44.61V15.32C45.43 15.2 46.28 15.13 47.15 15.13ZM25.66 57.17C27.09 57.17 28.22 58.28 28.25 59.71C28.25 61.12 27.1 62.3 25.69 62.3C24.3 62.28 23.17 61.15 23.15 59.74C23.15 58.33 24.26 57.2 25.67 57.18L25.66 57.17Z"
        fill="#2A388B"
      />
      <path
        d="M47.14 18.45C39.12 18.45 32.61 24.9599 32.61 32.9799C32.61 41 39.12 47.5099 47.14 47.5099C55.16 47.5099 61.67 41 61.67 32.9799C61.67 24.9599 55.16 18.45 47.14 18.45V18.45ZM41.2 41.68C40.56 41.89 40.67 41.42 40.7 41.06C40.73 40.69 41.09 35.7299 41.09 35.7299L46.43 39.7099C46.43 39.7099 41.98 41.4099 41.21 41.6899L41.2 41.68ZM47.14 38.7299L41.8 34.75L46.91 27.9L52.34 31.76L47.14 38.72V38.7299ZM54.55 28.7999L52.99 30.8899L47.65 26.91L49.21 24.82C49.59 24.31 50.32 24.2 50.83 24.58L54.31 27.18C54.82 27.56 54.93 28.2899 54.55 28.7999V28.7999Z"
        fill="#1281DD"
      />
    </svg>
  );
}
