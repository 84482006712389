import Link from './Link';
import React from 'react';
import { Flex } from 'rebass';
import SanityImage from '../Elements/SanityImage';

const Image = ({ src, style, transform, ...props }) => {
  return (
    <Flex {...props}>
      <SanityImage src={src} style={style} transform={transform} />
    </Flex>
  );
};

export default function ImageCards({ data }) {
  return (
    <div className="text-center py-12 md:py-24 max-w-screen-xl mx-auto w-full px-5 space-y-10">
      <h2>{data.title}</h2>
      <div className="flex flex-col md:flex-row w-full gap-10">
        {data.cards.map(card => (
          <Link
            noStyles
            data={{ page: card.button.page }}
            className="rounded-xl overflow-hidden bg-light-grey text-black flex-1 transition-all shadow-sm hover:text-black hover:shadow-2xl group"
          >
            <Image
              src={card.image}
              transform={transformer =>
                transformer
                  .height(400)
                  .width(800)
                  .fit('crop')
                  .auto('format')
                  .dpr(window?.devicePixelRatio || 1)
              }
            />
            <div className="py-10 px-5">
              <h3>{card.title}</h3>
              <p className="text-xl max-w-sm m-auto">{card.subtitle}</p>
              {card.button && (
                <Link
                  noStyles
                  className="mt-5 inline-block text-xl no-underline font-medium bg-dark-blue px-6 py-3 transition-all text-white rounded-xl border-2 border-dark-blue group-hover:text-dark-blue group-hover:bg-white"
                  data={{ page: card.button.page }}
                >
                  Learn more
                </Link>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
