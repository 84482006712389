import AAABanner from './AAABanner';
import Accordion from './Accordion';
import ArticleCard from './ArticleCard';
import AutismConnectBanner from './AutismConnectBanner';
import Breadcrumbs from './Breadcrumbs';
import Card from './Card';
import Columns from './Columns';
import EntryChoices from './EntryChoices';
import Footer from './Footer';
import Header from './Header';
import Heading from './Heading';
import Hero from './Hero';
import HighlightBox from './HighlightBox';
import Icon from './Icon';
import Iframe from './Iframe';
import Image from './Image';
import ImageCards from './ImageCards';
import KeyTakeaways from './KeyTakeaways';
import Link from './Link';
import MediaCard from './MediaCard';
import MediaResources from './MediaResources';
import PullQuote from './PullQuote';
import RichTextContent from './RichTextContent';
import SharedBlock from './SharedBlock';
import Tabs from './Tabs';
import Video from './Video';

export { Accordion };
export { ArticleCard };
export { Breadcrumbs };
export { Card };
export { Columns };
export { EntryChoices };
export { Footer };
export { Header };
export { Heading };
export { Hero };
export { HighlightBox };
export { Icon };
export { Iframe };
export { Image };
export { ImageCards };
export { KeyTakeaways };
export { Link };
export { MediaCard };
export { MediaResources };
export { PullQuote };
export { RichTextContent };
export { SharedBlock };
export { Tabs };
export { Video };

export const componentRegistry = {
  aaaBannerComponent: AAABanner,
  accordionComponent: Accordion,
  articleCardComponent: ArticleCard,
  autismConnectBannerComponent: AutismConnectBanner,
  breadcrumbsComponent: Breadcrumbs,
  cardComponent: Card,
  columnsComponent: Columns,
  entryChoicesComponent: EntryChoices,
  footerComponent: Footer,
  headerComponent: Header,
  headingComponent: Heading,
  heroComponent: Hero,
  highlightBoxComponent: HighlightBox,
  iconComponent: Icon,
  iFrameComponent: Iframe,
  imageCardsComponent: ImageCards,
  imageComponent: Image,
  keyTakeawaysComponent: KeyTakeaways,
  link: Link,
  mediaCardComponent: MediaCard,
  mediaResourceComponent: MediaResources,
  pullQuoteComponent: PullQuote,
  richTextComponent: RichTextContent,
  sharedBlockComponent: SharedBlock,
  tabsComponent: Tabs,
  videoComponent: Video,
};
