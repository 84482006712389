import React from 'react';
import { Flex, Text } from 'rebass';
import { Link as GatsbyLink } from 'gatsby';
import styled from '@emotion/styled';
import Container from '~components/Elements/Container';
import Icon from '~components/Elements/Icon';
import Link from '~components/PageBlocks/Link';
import { useMobileScreen } from '~lib/responsive';

const Wrapper = styled(Flex)`
  background-color: #fff;
  border-bottom: 1px solid rgba(51, 51, 51, 0.13);
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 14px;

  @media (max-width: ${props => props.theme.device.md}) {
    line-height: 1.5;
  }
`;

const getLevel = (menu, page) =>
  menu?.page?._id === page?._id ||
  (menu?.items && menu?.items?.find(item => getLevel(item, page)));

const getBreadcrumbs = (menu, page) => {
  let level = menu;
  const parents = [
    {
      title: `Autism in ${menu.section}`,
      url: `/explore/${menu.section}`,
    },
  ];

  while (level) {
    if (level.page) {
      parents.push(level.page);
    }
    level = getLevel(level, page);
  }

  return parents;
};

export default function Breadcrumbs({ data }) {
  let breadcrumbs = getBreadcrumbs(data.menu, data.article);
  const isMobile = useMobileScreen();

  if (isMobile) {
    const prevPage = breadcrumbs[breadcrumbs.length - 2];
    breadcrumbs = [prevPage];
  }

  return (
    <Wrapper>
      <Container
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {isMobile ? null : (
          <GatsbyLink to="/">
            <Icon name="breadcrumb-home" clickable={true} />
          </GatsbyLink>
        )}
        {breadcrumbs.map(page => {
          return (
            <Flex key={page._id || page.url}>
              {isMobile ? (
                <Icon name="breadcrumb-chevron-left" mx="8px" />
              ) : (
                <Icon name="breadcrumb-chevron-right" mx="8px" />
              )}
              <BreadcrumbLink
                page={page}
                isMobile={isMobile}
                article={data.article}
              />
            </Flex>
          );
        })}
      </Container>
    </Wrapper>
  );
}

function BreadcrumbLink({ page, article, isMobile }) {
  return page._id && page._id === article._id ? (
    <Text>{page.title}</Text>
  ) : (
    <Link data={page.url ? { url: page.url } : { page }}>
      {isMobile ? 'Back to ' : null}
      {page.title}
    </Link>
  );
}
