import React from 'react';
import IframeResizer from 'iframe-resizer-react';

const Iframe = ({ data: { url, height, width }, ...props }) => {
  const resizable = true;

  return resizable ? (
    <IframeResizer
      log
      id="keela_3zKcdYNiGedGJ4w67"
      src={url}
      frameBorder="0"
      heightCalculationMethod="max"
      scrolling={true}
      style={{
        width: '1px',
        minWidth: '100%',
        minHeight: `${height || '500'}px`,
      }}
    />
  ) : (
    <iframe
      title="Careers"
      id="keela_3zKcdYNiGedGJ4w67"
      width={width || '100%'}
      height={height || '500'}
      src={url}
    />
  );
};

export default Iframe;
