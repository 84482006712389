export default function Journey() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.83 32.94C35.19 38.29 43.53 45.77 39.51 49.92C36.84 52.68 33.05 53.52 27.14 52.32C24.73 55.46 20.5 60.45 20.5 60.45C34.78 62.48 43.43 58.97 46.3 51.11C49.16 43.01 43.73 42.17 44.06 36.45C44.33 31.72 50.32 28.6 54.09 26.65C53.58 25.71 52.68 24.62 52.68 24.62C52.68 24.62 45.64 27.85 40.83 32.94Z"
        fill="#1281DD"
      />
      <path
        d="M17.42 59.7299C16.93 59.0499 16.5 58.44 16.07 57.83C12.95 53.52 9.76001 49.2499 6.72001 44.8699C4.19001 41.2299 2.03001 37.3599 0.850013 33.0399C-1.08999 25.9599 0.410013 19.7599 5.83001 14.7099C13.25 7.79995 25.48 9.05995 31.43 17.2899C35.55 22.9899 35.7 29.0999 33.28 35.4599C31.15 41.0399 27.76 45.88 24.25 50.65C22.19 53.44 20.11 56.21 18.04 58.99C17.87 59.22 17.68 59.4299 17.43 59.7299H17.42ZM8.48001 27.0599C8.44001 31.9599 12.4 36.0399 17.25 36.0899C22.25 36.1399 26.38 32.2199 26.43 27.3599C26.48 22.1599 22.55 18.1199 17.43 18.0999C12.52 18.0799 8.53001 22.0699 8.49001 27.0499L8.48001 27.0599Z"
        fill="#2A388B"
      />
      <path
        d="M55.91 25.6399C53.8 22.5399 51.72 19.61 49.79 16.58C49.1 15.5 48.65 14.21 48.34 12.96C47.27 8.60995 49.64 4.46995 53.78 3.29995C57.97 2.11995 62.13 4.31995 63.56 8.56995C64.4 11.07 63.89 13.52 62.63 15.72C61.19 18.23 59.49 20.6 57.87 23.02C57.31 23.86 56.67 24.65 55.92 25.65L55.91 25.6399ZM60.12 10.66C60.12 8.33995 58.28 6.50995 55.95 6.51995C53.69 6.51995 51.84 8.35995 51.83 10.63C51.81 12.93 53.67 14.81 55.97 14.82C58.3 14.82 60.13 13 60.13 10.67L60.12 10.66Z"
        fill="#2A388B"
      />
    </svg>
  );
}
