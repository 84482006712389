import Link from './Link';
import { VideoBase } from './Video';

const HeroComponent = ({ data }) => {
  return (
    <div className="mx-auto px-5 py-12 md:py-24 gap-x-8 flex flex-col md:flex-row w-full max-w-screen-xl space-y-10 md:space-y-0">
      <div className="w-auto">
        <h1 className="font-bold text-dark-blue">{data.title}</h1>
        {data.subtitle.split('\n').map(line => (
          <p className="text-xl" key={line}>
            {line}
          </p>
        ))}
        {data.button && (
          <Link
            noStyles
            className="mt-5 inline-block text-xl no-underline font-medium transition-all bg-dark-blue border-2 border-dark-blue px-6 py-3 text-white rounded-xl hover:text-dark-blue hover:bg-white"
            data={{ page: data?.button?.page }}
          >
            Start a pathway
          </Link>
        )}
      </div>
      <div className="w-full relative overflow-hidden rounded-2xl aspect-video">
        <VideoBase url={data.video.url} isIndex={true}></VideoBase>
      </div>
    </div>
  );
};

export default HeroComponent;
